import * as React from "react";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SettingsIcon from "@mui/icons-material/Settings";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArticleIcon from "@mui/icons-material/Article";
import SchoolIcon from "@mui/icons-material/School";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import VerifiedIcon from "@mui/icons-material/Verified";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import SyncIcon from "@mui/icons-material/Sync";
import {
  Collapse,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import {
  AbDivider,
  useAbNavigator,
  limitTextLinesMixin,
} from "@advocatebase/web-components";
import useAuth from "../../store/useAuth";
import useNavLinks from "./useNavLinks";
import useCustomLabels from "./useCustomLabels";

const openedMixin = () => ({
  width: (theme) => theme.drawerWidth,
  transition: (theme) =>
    theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  overflowX: "hidden",
});

const closedMixin = () => ({
  transition: (theme) =>
    theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  overflowX: "hidden",
  width: (theme) => `calc(${theme.spacing(7)} + 1px)`,
});

const CustomDrawer = ({
  isMobile = false,
  isLaptop = false,
  mobileOpen = false,
  onClose = () => {},
}) => {
  const nav = useAbNavigator();
  const [open, setOpen] = React.useState(!isLaptop);
  const [openAdminList, setOpenAdminList] = React.useState(false);
  const auth = useAuth();
  const linksObject = useNavLinks();
  const customLabels = useCustomLabels();

  const handleOpen = React.useCallback(
    (shouldOpen) => {
      setOpen(shouldOpen);
      if (!shouldOpen) {
        setOpenAdminList(false);
        onClose();
      }
    },
    [onClose]
  );

  const itemClick = () => {
    if (isMobile) {
      handleOpen(false);
    }
  };

  React.useEffect(() => {
    if (isMobile) {
      handleOpen(mobileOpen);
    }
  }, [mobileOpen, isMobile, handleOpen]);

  return (
    customLabels &&
    linksObject && (
      <Drawer
        data-dark={!isMobile}
        variant={isMobile ? "temporary" : "permanent"}
        open={open}
        onClose={() => handleOpen(false)}
        sx={{
          width: (theme) => theme.drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: (theme) => theme.drawerWidth,
            boxSizing: "border-box",
          },
          ...(open && {
            ...openedMixin(),
            "& .MuiDrawer-paper": openedMixin(),
          }),
          ...(!open && {
            ...closedMixin(),
            "& .MuiDrawer-paper": closedMixin(),
          }),
        }}
      >
        <Toolbar variant={isMobile ? "regular" : "dense"} />
        <List>
          {linksObject.map((item) => (
            <ListItemButton
              data-dark={!isMobile}
              key={item.label}
              href={!isMobile ? item.to : undefined} // This enables right clicking to open in a new tab - only allow on desktop
              onClick={(e) => {
                e.preventDefault();
                nav.to(item.to);
                itemClick();
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText
                primary={item.label}
                sx={limitTextLinesMixin({ maxLines: 1 })}
              />
            </ListItemButton>
          ))}
        </List>
        <AbDivider variant={!isMobile ? "baseWhite" : undefined} />
        {auth.showAdminSection && (
          <>
            <List>
              <ListItemButton
                data-dark={!isMobile}
                onClick={() => {
                  setOpenAdminList(!openAdminList);
                  setOpen(true);
                }}
              >
                <ListItemIcon>
                  <AdminPanelSettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Admin" />
                {openAdminList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
              <Collapse in={openAdminList} timeout="auto" unmountOnExit>
                {[
                  auth.showUsers && {
                    label: "Users",
                    icon: <AdminPanelSettingsIcon />,
                    to: "/admin/users",
                  },
                  auth.showCustomPageEditor && {
                    label: "Custom Pages",
                    icon: <ArticleIcon />,
                    to: "/admin/pages",
                  },
                  auth.showQualsEditor && {
                    label: customLabels.quals.plural,
                    icon: <VerifiedIcon />,
                    to: "/admin/quals",
                  },
                  auth.showQualsEditor && {
                    label: "Training Sessions",
                    icon: <SchoolIcon />,
                    to: "/admin/trainingsessions",
                  },
                  auth.showConsents && {
                    label: "Consents",
                    icon: <HistoryEduIcon />,
                    to: "/admin/consents",
                  },
                  auth.showImportPeople && {
                    label: "Import People",
                    icon: <ImportContactsIcon />,
                    to: "/admin/import",
                  },
                  auth.showIntegrations && {
                    label: "Integrations",
                    icon: <SyncIcon />,
                    to: "/admin/integrations",
                  },
                  !!process.env.REACT_APP_SHOW_ADMIN_SETTINGS && {
                    label: "Settings",
                    icon: <SettingsIcon />,
                    to: "/admin/settings",
                  },
                ]
                  .filter((x) => !!x)
                  .map((item) => (
                    <ListItemButton
                      data-dark={!isMobile}
                      key={item.label}
                      sx={{ pl: 4 }}
                      href={!isMobile ? item.to : undefined} // This enables right clicking to open in a new tab - only allow on desktop
                      onClick={(e) => {
                        e.preventDefault();
                        nav.to(item.to);
                        itemClick();
                      }}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText
                        primary={item.label}
                        sx={limitTextLinesMixin({ maxLines: 1 })}
                      />
                    </ListItemButton>
                  ))}
              </Collapse>
            </List>
            <AbDivider variant={!isMobile ? "baseWhite" : undefined} />
          </>
        )}
        {!isMobile && (
          <List
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column-reverse",
            }}
          >
            <ListItemButton
              data-dark={!isMobile}
              key="toggleOpen"
              onClick={() => {
                handleOpen(!open);
              }}
              sx={{ flexGrow: 0, ...(open && { justifyContent: "end" }) }}
            >
              <ListItemIcon sx={open ? { justifyContent: "end" } : {}}>
                {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </ListItemIcon>
            </ListItemButton>
          </List>
        )}
      </Drawer>
    )
  );
};

export default CustomDrawer;
