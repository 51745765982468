import React from "react"
import { Navigate } from "react-router-dom";

const ViewTeamPage = React.lazy(() => import("../pages/teams/ViewTeamPage"));
const ViewPersonPage = React.lazy(() => import("../pages/people/ViewPersonPage"));
const ViewAllPeoplePage = React.lazy(() => import("../pages/people/ViewAllPeoplePage"));
const ViewAllTeamsPage = React.lazy(() => import("../pages/teams/ViewAllTeamsPage"));
const ViewAllOrgUsersPage = React.lazy(() => import("../pages/admin/ViewAllOrgUsersPage"));
const ViewAllEventsPage = React.lazy(() => import("../pages/events/ViewAllEventsPage"));
const ViewEventPage = React.lazy(() => import("../pages/events/ViewEventPage"));
const ViewOrgUserPage = React.lazy(() => import("../pages/admin/ViewOrgUserPage"));
const EditOrgUserPage = React.lazy(() => import("../pages/admin/EditOrgUserPage"));
const AdminPage = React.lazy(() => import("../pages/admin/AdminPage"));
const NewOrgUserPage = React.lazy(() => import("../pages/admin/NewOrgUserPage"));
const PageEditorPage = React.lazy(() => import("../pages/pageEditor/PageEditorPage"));
const ViewAllQualsPage = React.lazy(() => import("../pages/quals/ViewAllQualsPage"));
const ViewQualPage = React.lazy(() => import("../pages/quals/ViewQualPage"));
const EditQualPage = React.lazy(() => import("../pages/quals/EditQualPage"));
const ViewAllConsentRecordsPage = React.lazy(() => import("../pages/consents/ViewAllConsentRecordsPage"));
const ViewConsentRecordPage = React.lazy(() => import("../pages/consents/ViewConsentRecordPage"));
const ViewAllConsentDefinitionsPage = React.lazy(() => import("../pages/consents/ViewAllConsentDefinitionsPage"));
const NewConsentDefinitionPage = React.lazy(() => import("../pages/consents/NewConsentDefinitionPage"));
const ViewConsentDefinitionPage = React.lazy(() => import("../pages/consents/ViewConsentDefinitionPage"));
const ConsentsPage = React.lazy(() => import("../pages/admin/ConsentsPage"));
const ViewAllTrainingSessionsPage = React.lazy(() => import("../pages/quals/ViewAllTrainingSessionsPage"));
const ViewTrainingSessionPage = React.lazy(() => import("../pages/quals/ViewTrainingSessionPage"));
const ImportPage = React.lazy(() => import("../pages/import/ImportPage"));
const ViewAllIntegrationsPage = React.lazy(() => import("../pages/admin/ViewAllIntegrationsPage"));
const ViewIntegrationPage = React.lazy(() => import("../pages/admin/ViewIntegrationPage"));

export const ROUTE_SLUGS = {
  DASHBOARD: "/",
  RESOURCES: "/resources",
};

export const HOME_SLUG = ROUTE_SLUGS.DASHBOARD;

export const generateRoutes = (auth) =>
  [
    auth.showAdminSection && {
      path: "/admin",
      element: AdminPage,
    },
    auth.showUsers && [
      {
        path: "/admin/users",
        element: ViewAllOrgUsersPage,
      },
      {
        path: "/admin/users/:personId",
        element: ViewOrgUserPage,
      },
      {
        path: "/admin/users/:personId/edit",
        element: EditOrgUserPage,
      },
      {
        path: "/admin/users/new",
        element: NewOrgUserPage,
      },
    ],
    auth.showIntegrations && [
      {
        path: "/admin/integrations",
        element: ViewAllIntegrationsPage,
      },
      {
        path: "/admin/integrations/:integrationId",
        element: ViewIntegrationPage,
      },
    ],
    auth.showCustomPageEditor && {
      path: "/admin/pages",
      element: PageEditorPage,
    },
    auth.showQualsEditor && [
      {
        path: "/admin/quals",
        element: ViewAllQualsPage,
      },
      {
        path: "/admin/quals/new",
        element: EditQualPage,
        options: { isNew: true },
      },
      {
        path: "/admin/quals/:id/edit",
        element: EditQualPage,
      },
      {
        path: "/admin/quals/:id",
        element: ViewQualPage,
      },
      {
        path: "/admin/trainingsessions",
        element: ViewAllTrainingSessionsPage,
      },
      {
        path: "/admin/trainingsessions/:id",
        element: ViewTrainingSessionPage,
      },
    ],
    auth.showConsents && {
      path: "/admin/consents",
      element: ConsentsPage,
    },
    auth.showImportPeople && {
      path: "/admin/import",
      element: ImportPage,
    },
    auth.showConsentRecords && [
      {
        path: "/admin/consents/records",
        element: ViewAllConsentRecordsPage,
      },
      {
        path: "/admin/consents/records/:id",
        element: ViewConsentRecordPage,
      },
    ],
    auth.showConsentDefinitions && [
      {
        path: "/admin/consents/definitions",
        element: ViewAllConsentDefinitionsPage,
      },
      {
        path: "/admin/consents/definitions/new",
        element: NewConsentDefinitionPage,
      },
      {
        path: "/admin/consents/definitions/:id",
        element: ViewConsentDefinitionPage,
      },
    ],
    auth.showEvents &&
      [
        {
          path: "/events",
          element: ViewAllEventsPage,
        },
        {
          path: "/events/:id",
          element: ViewEventPage,
        },
      ].flat(),
    auth.showTeams && [
      {
        path: "/teams",
        element: ViewAllTeamsPage,
      },
    ],
    !auth.showTeams && {
      path: "/teams",
      element: Navigate,
      options: { to: HOME_SLUG },
    },
    // Note - if the specific team is not accessable, the ViewTeamPage will redirect to /notfound
    {
      path: "/teams/:id",
      element: ViewTeamPage,
    },
    auth.showPeople && [
      {
        path: "/people",
        element: ViewAllPeoplePage,
      },
      {
        path: "/people/:id",
        element: ViewPersonPage,
      },
    ],
  ]
    .flat()
    .filter(Boolean);
