import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "../navigation/RequireAuth";
import _ from "lodash";
import DynamicPage from "../pages/standard/DynamicPage";
import { ViewMode } from "../../store/types";
import * as profileRoutes from "./profileRoutes";
import * as adminPortalRoutes from "./adminPortalRoutes";
import * as organisationRoutes from "./organisationRoutes";
import * as basicRoutes from "./basicRoutes";
import { NOT_AUTH_ROUTE_PATHS } from "./NotAuthRouter";
import useAuth from "../../store/useAuth";
import { generatePageTreeRoutes } from "./generatePageTreeRoutes";
import { useGetPageTreeQuery } from "../../services/pageApi";
import { Box, CircularProgress } from "@mui/material";

const generateRoutes = (auth) => {
  switch (auth.viewMode) {
    case ViewMode.ADVOCATEBASE:
      return [
        profileRoutes.generateRoutes({
          organisationHomeSlug: organisationRoutes.HOME_SLUG,
          adminPortalHomeSlug: adminPortalRoutes.HOME_SLUG,
        }),
        adminPortalRoutes.generateRoutes(),
        basicRoutes.generateRoutes({
          homePageSlug: adminPortalRoutes.HOME_SLUG,
          loginPageSlug: NOT_AUTH_ROUTE_PATHS.LOGIN,
        }),
      ].flat(1);
    case ViewMode.ORGANISATION:
      return [
        profileRoutes.generateRoutes({
          organisationHomeSlug: organisationRoutes.HOME_SLUG,
          adminPortalHomeSlug: adminPortalRoutes.HOME_SLUG,
        }),
        organisationRoutes.generateRoutes(auth),
        basicRoutes.generateRoutes({
          homePageSlug: organisationRoutes.HOME_SLUG,
          loginPageSlug: NOT_AUTH_ROUTE_PATHS.LOGIN,
        }),
      ].flat(1);
    default:
      return [
        profileRoutes.generateRoutes({
          organisationHomeSlug: organisationRoutes.HOME_SLUG,
          adminPortalHomeSlug: adminPortalRoutes.HOME_SLUG,
        }),
        basicRoutes.generateRoutes({
          homePageSlug: profileRoutes.HOME_SLUG,
          loginPageSlug: NOT_AUTH_ROUTE_PATHS.LOGIN,
        }),
      ].flat(1);
  }
};

const AuthRouter = () => {
  const auth = useAuth();
  const pageTreeQuery = useGetPageTreeQuery();
  const [pageRoutes, setPageRoutes] = React.useState([]);

  React.useEffect(() => {
    if (pageTreeQuery.isSuccess && pageTreeQuery.data) {
      setPageRoutes(generatePageTreeRoutes(pageTreeQuery.data));
    }
  }, [pageTreeQuery, setPageRoutes]);

  const dynamicPages = pageRoutes.map((route) => ({
    ...route,
    element: DynamicPage,
  }));

  return (
    <Suspense
      fallback={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      }
    >
      <Routes>
        {generateRoutes(auth)
          .concat(dynamicPages)
          .map((route) => (
            <Route
              path={route.path}
              element={
                <RequireAuth loginPageSlug={NOT_AUTH_ROUTE_PATHS.LOGIN}>
                  <route.element {...route.options} path={route.path} />
                </RequireAuth>
              }
              key={route.path}
            />
          ))}
      </Routes>
    </Suspense>
  );
};

export default AuthRouter;
