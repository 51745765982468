import * as React from "react";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import _ from "lodash";
import {
  Page,
  PageTreeRoute,
  generatePageTreeRoutes,
} from "../routes/generatePageTreeRoutes";
import { useGetPageTreeQuery } from "../../services/pageApi";
import { AbLink, ContentRendererType } from "@advocatebase/web-components";

const DirectoryRenderer: ContentRendererType = () => {
  const pageTreeQuery = useGetPageTreeQuery();
  const [routes, setRoutes] = React.useState<PageTreeRoute[]>([]);
  const location = useLocation();

  React.useEffect(() => {
    if (pageTreeQuery.isSuccess && pageTreeQuery.data) {
      setRoutes(generatePageTreeRoutes(pageTreeQuery.data as Page));
    }
  }, [pageTreeQuery, setRoutes]);

  const currentRoute = routes?.find(
    (route) => route.path === _.trimEnd(location.pathname, "/")
  );

  return currentRoute && currentRoute.children.length > 0 ? (
    <ul style={{ marginTop: 0 }}>
      {currentRoute.children.map((route, i) => (
        <li key={i}>
          <Typography variant="body1">
            <AbLink href={route.slug}>{route.title}</AbLink>
          </Typography>
        </li>
      ))}
    </ul>
  ) : null;
};

export default DirectoryRenderer;
