import React from "react";
import { Socket, io } from "socket.io-client";
import { API_BASE_URL } from "./consts";
import baseApi from "./baseApi";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { logger } from "@advocatebase/toolbox";

export const useSocketListeners = () => {
  const token: string | undefined = useSelector(
    (state: any) => state.auth.token
  );
  const dispatch = useDispatch();
  const orgId = useSelector((state) => (state as any).auth?.activeOrgId);
  const socket = React.useRef<Socket>();

  React.useEffect(() => {
    socket.current?.disconnect(); // disconnect if orgId or token (or dispatch) changes, before reconnecting
    if (token && orgId) {
      socket.current = io(API_BASE_URL, {
        auth: {
          Authorization: `Bearer ${token}`,
        },
        extraHeaders: { Authorization: `Bearer ${token}` },
      });
      socket.current.on("connect", () => {
        logger.success("WebSocket connected");
      });
      socket.current.on("connect_error", (err: Error) => {
        logger.debug("WebSocket connection error", err);
      });
      socket.current.on("disconnect", (reason, description) => {
        logger.debug(`WebSocket disconnected - ${reason}: ${description}`);
      });
      socket.current.on("notificationEvent", (notification) => {
        if (notification.id) {
          // Redux Toolkit's recommended approach is to request a cache refresh rather than mutate the cache with the new object [https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#overview]
          dispatch(baseApi.util.invalidateTags(["Notifications"]));
          // Note - do not trigger a push notification here. Push notifications are sent by the server directly to the service worker, which runs independently of this app (and can keep on running even when this app is closed).
        }
      });
    }
  }, [token, dispatch, orgId]);
};
