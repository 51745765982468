import React from "react";
import { Navigate, To, useLocation } from "react-router-dom";
import useAuth from "../../store/useAuth";
import { AuthStatus } from "../../store/types";

interface RequireNotAuthProps {
  children: React.ReactNode;
  redirectTo: To;
}

const RequireNotAuth = ({ children, redirectTo }: RequireNotAuthProps) => {
  const location = useLocation();
  const auth = useAuth();

  if (auth.status === AuthStatus.PENDING) {
    return null;
  } else if (auth.status === AuthStatus.AUTHORISED) {
    return <Navigate to={redirectTo} state={{ from: location }} />;
  }

  return children;
};

export default RequireNotAuth;
