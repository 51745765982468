import useAuth from "../../../store/useAuth";
import { useLocation, useParams } from "react-router-dom";
import { AuthStatus } from "../../../store/types";
import { useAbNavigator, useAbSnackbar } from "@advocatebase/web-components";
import { useAppDispatch } from "../../../store";
import { setOrganisation } from "../../../store/authSlice";

interface AutoChangeOrgPageProps {
  notFoundPageSlug: string;
}

const AutoChangeOrgPage = ({ notFoundPageSlug }: AutoChangeOrgPageProps) => {
  const auth = useAuth();
  const { orgId } = useParams();
  const location = useLocation();
  const nav = useAbNavigator();
  const queryParams = new URLSearchParams(location.search);
  const dispatch = useAppDispatch();
  const snackbar = useAbSnackbar();
  const redirect = queryParams.get("redirect");

  if (
    !!orgId &&
    auth.status === AuthStatus.AUTHORISED &&
    auth.tokenPayload.organisations.find((org) => org.id === orgId) &&
    typeof redirect === "string"
  ) {
    if (auth.activeOrgId !== orgId) {
      // Change org (only if needed)
      snackbar.loading();
      dispatch(
        setOrganisation({
          orgId,
          onSuccess: () => {
            snackbar.clear();
            nav.to(redirect);
          },
          onFailure: () => {
            snackbar.clear();
            nav.to(notFoundPageSlug);
          },
        })
      );
    } else {
      nav.to(redirect);
    }
  } else {
    nav.to(notFoundPageSlug);
  }

  return null;
};

export default AutoChangeOrgPage;
