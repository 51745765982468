import React from "react";

const EditOrgPage = React.lazy(() =>
  import("../pages/organisations/EditOrgPage")
);
const ViewAllOrgsPage = React.lazy(() =>
  import("../pages/organisations/ViewAllOrgsPage")
);
const ViewOrgPage = React.lazy(() =>
  import("../pages/organisations/ViewOrgPage")
);
const AdminDashboardPage = React.lazy(() =>
  import("../pages/advocatebaseAdmin/AdminDashboardPage")
);
const ViewAllUsersPage = React.lazy(() =>
  import("../pages/advocatebaseAdmin/ViewAllUsersPage")
);
const ViewUserPage = React.lazy(() =>
  import("../pages/advocatebaseAdmin/ViewUserPage")
);
const EditUserPage = React.lazy(() =>
  import("../pages/advocatebaseAdmin/EditUserPage")
);

export const ROUTE_SLUGS = {
  VIEW_ALL_ORGANISATIONS: "/organisations",
  VIEW_ORGANISATION: "/organisations/:id",
  NEW_ORGANISATION: "/organisations/new",
  EDIT_ORGANISATION: "/organisations/:id/edit",
  VIEW_ALL_USERS: "/users",
  VIEW_USER: "/users/:id",
  NEW_USER: "/users/new",
  EDIT_USER: "/users/:id/edit",
};

export const HOME_SLUG = "/home";

export const generateRoutes = () => [
  {
    path: HOME_SLUG,
    element: AdminDashboardPage,
  },
  {
    path: ROUTE_SLUGS.VIEW_ALL_ORGANISATIONS,
    element: ViewAllOrgsPage,
  },
  {
    path: ROUTE_SLUGS.VIEW_ORGANISATION,
    element: ViewOrgPage,
  },
  {
    path: ROUTE_SLUGS.NEW_ORGANISATION,
    element: EditOrgPage,
    options: { isNew: true },
  },
  {
    path: ROUTE_SLUGS.EDIT_ORGANISATION,
    element: EditOrgPage,
  },
  {
    path: ROUTE_SLUGS.VIEW_ALL_USERS,
    element: ViewAllUsersPage,
  },
  {
    path: ROUTE_SLUGS.VIEW_USER,
    element: ViewUserPage,
  },
  {
    path: ROUTE_SLUGS.NEW_USER,
    element: EditUserPage,
    options: { isNew: true },
  },
  {
    path: ROUTE_SLUGS.EDIT_USER,
    element: EditUserPage,
  },
];
