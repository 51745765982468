import React from "react";

const OrganisationSelectPage = React.lazy(() =>
  import("../pages/login/OrganisationSelect")
);
const AccountPage = React.lazy(() => import("../pages/profile/AccountPage"));
const ProfilePage = React.lazy(() => import("../pages/profile/ProfilePage"));
const SecurityPage = React.lazy(() => import("../pages/profile/SecurityPage"));
const SettingsPage = React.lazy(() => import("../pages/profile/SettingsPage"));

export const ROUTE_SLUGS = {
  ACCOUNT: "/account",
  PROFILE: "/account/profile",
  SECURITY: "/account/security",
  SETTINGS: "/account/settings/*",
  ORGANISATION_SELECT: "/organisationselect",
};

export const HOME_SLUG = ROUTE_SLUGS.ORGANISATION_SELECT;

export const generateRoutes = ({ organisationHomeSlug, adminPortalHomeSlug }) =>
  [
    {
      path: ROUTE_SLUGS.ACCOUNT,
      element: AccountPage,
    },
    {
      path: ROUTE_SLUGS.PROFILE,
      element: ProfilePage,
    },
    {
      path: ROUTE_SLUGS.SECURITY,
      element: SecurityPage,
    },
    {
      path: ROUTE_SLUGS.SETTINGS,
      element: SettingsPage,
    },
    {
      // Note, there is a similar AutoChangeOrgPage in basicRoutes.js
      path: ROUTE_SLUGS.ORGANISATION_SELECT,
      element: OrganisationSelectPage,
      options: {
        organisationHomeSlug,
        adminPortalHomeSlug,
      },
    },
  ].filter(Boolean);
