import { configureStore, Action } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import authSlice, {
  logout,
  setAdvocateBaseAdminViewType,
  setOrganisationType,
} from "./authSlice";
import baseApi from "../services/baseApi";
import { combineReducers } from "redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { isNotProduction } from "@advocatebase/toolbox";
import { useDispatch, useSelector } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import { AuthStatus, State } from "./types";

const persistConfig = {
  key: "auth",
  version: 1,
  storage,
  whitelist: ["auth"],
};

const appReducer = combineReducers<State>({
  auth: authSlice,
  // Add the generated api reducers as specific top-level slices
  [baseApi.reducerPath]: baseApi.reducer,
});

const rootReducer = (state: State | undefined, action: Action) => {
  // This root reducer is wrapper function for "appReducer" (above).
  if (action.type === logout.type) {
    // Clear the entire redux store on logout
    return appReducer(undefined, action);
  } else if (
    [
      `${setOrganisationType}/fulfilled`,
      `${setAdvocateBaseAdminViewType}/fulfilled`,
    ].includes(action.type)
  ) {
    // When the active organisation is changed, wipe all API data except auth (which is common across organisations)
    return appReducer(
      { auth: state?.auth ?? { status: AuthStatus.PENDING } },
      action
    );
  }

  return appReducer(state, action);
};

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  devTools: isNotProduction(),
  // Adding the api middleware enables caching, invalidation, polling, and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(baseApi.middleware),
});

setupListeners(store.dispatch);
export const persistor = persistStore(store);

// Export hooks that can be used to resolve types (ie, a typescript-friendly version of useDispatch and useSelector - see here: https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type and here: https://redux-toolkit.js.org/tutorials/typescript#define-typed-hooks)
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<State> = useSelector;

export default store;
