export enum AuthStatus {
  PENDING = "pending",
  AUTHORISED = "authorised",
  UNAUTHORISED = "unauthorised",
}

export enum ViewMode {
  ADVOCATEBASE = "advocatebase",
  ORGANISATION = "organisation",
}

// Note - these three types of roles are idential to their counterparts on the server
export enum AdvocateBaseRole {
  ADMIN = "admin",
  USER = "user",
}

export enum OrganisationRole {
  ADMIN = "admin",
  MANAGER = "manager",
  USER = "user",
}

export enum TeamRole {
  ADMIN = "admin",
  MANAGER = "manager",
  USER = "user",
  ASSIGNED = "assigned",
}

export interface TokenPayload {
  id: string;
  jwt_version: number;
  role: AdvocateBaseRole;
  organisations: {
    id: string;
    role: OrganisationRole;
    personId: string | null;
    teams: {
      id: string;
      role: TeamRole;
    }[];
    qualifications: string[];
  }[];
  iat: number;
  exp: number;
}

export type AuthState =
  | {
      status: AuthStatus.AUTHORISED;
      token: string;
      tokenPayload: TokenPayload;
      activeOrgId: string | null;
      viewMode: ViewMode | null;
    }
  | {
      status: AuthStatus.PENDING | AuthStatus.UNAUTHORISED;
    };

export interface State {
  auth: AuthState;
  api?: any;
}
