import React from "react";
import { Navigate } from "react-router-dom";
import AutoChangeOrgPage from "../pages/login/AutoChangeOrgPage";

const DashboardPage = React.lazy(() =>
  import("../pages/dashboard/DashboardPage")
);
const NotFoundPage = React.lazy(() => import("../pages/standard/NotFoundPage"));

const NOT_FOUND_SLUG = "/notfound";

export const generateRoutes = ({ homePageSlug, loginPageSlug }) => [
  {
    path: "/",
    element: DashboardPage,
  },
  {
    // In case the location has not changed on login
    path: loginPageSlug,
    element: Navigate,
    options: { to: homePageSlug },
  },
  {
    // Note, there is a similar OrganisationSelectPage in profileRoutes.js
    path: "/o/:orgId",
    element: AutoChangeOrgPage,
    options: {
      notFoundPageSlug: NOT_FOUND_SLUG,
    },
  },
  // Keep /notfound reserved for the NotFoundPage. (NB - no need to add it explicitly here; if no such route is specified, it will default to the catch-all NotFoundPage)
  {
    path: "*",
    element: NotFoundPage,
    options: { homePageSlug },
  },
];
