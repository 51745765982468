import React from "react";
import { Box } from "@mui/material";
import CustomDrawer from "./navigation/CustomDrawer";
import CustomAppBar from "./navigation/CustomAppBar";
import { logout } from "../store/authSlice";
import AuthRouter from "./routes/AuthRouter";
import NotAuthRouter from "./routes/NotAuthRouter";
import useAuth from "../store/useAuth";
import FixedWrapper from "./FixedWrapper";
import {
  AbDevNotice,
  useIsMobile,
  useIsLaptop,
} from "@advocatebase/web-components";
import { useSocketListeners } from "../services/socket";
import { useAppDispatch } from "../store";
import { AuthStatus } from "../store/types";

const AppLayout = () => {
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const isLaptop = useIsLaptop();
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const auth = useAuth();
  const [headerHeight, setHeaderHeight] = React.useState<number | null>(null);

  React.useEffect(() => {
    // If auth status is "pending" (ie, default - no auth status was set by redux-persist), then it means that no auth status was stored by redux-persist and therefore login() needs to be called.
    if (auth.status === AuthStatus.PENDING) {
      dispatch(logout());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useSocketListeners(); // Listen for notifications from Socket.io

  if (auth.status === AuthStatus.PENDING) {
    return null;
  } else {
    return (
      <Box data-id="app-container">
        <AbDevNotice />
        {auth.status === AuthStatus.AUTHORISED && (
          <FixedWrapper
            sx={{ top: 0, zIndex: (theme) => theme.zIndex.drawer + 1 }}
            onChangeHeight={setHeaderHeight}
          >
            <CustomAppBar
              data-id="app-bar"
              profilePageSlug="/account/profile"
              securityPageSlug="/account/security"
              settingsPageSlug="/account/settings"
              changeOrganisationPageSlug="/organisationselect"
              onDrawerOpen={() => setMobileDrawerOpen(true)}
              onDrawerClose={() => setMobileDrawerOpen(false)}
              drawerOpen={mobileDrawerOpen}
            />
          </FixedWrapper>
        )}
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {auth.status === AuthStatus.AUTHORISED && (
            // Header placeholder - to prevent content from being hidden beneath the header
            <Box
              sx={{
                width: "100%",
                height: headerHeight,
                flexGrow: 0,
                flexShrink: 0,
              }}
            />
          )}
          <Box
            data-id="app-main-container"
            sx={{
              width: "100%",
              flexGrow: 1,
              flexShrink: 1,
              display: "flex",
              flexDirection: "row",
              // overflow: "hidden",
              overflowX: "hidden",
            }}
          >
            {auth.status === AuthStatus.AUTHORISED && !!auth.viewMode && (
              <Box component="nav" data-id="app-nav-container">
                <CustomDrawer
                  isMobile={isMobile}
                  isLaptop={isLaptop}
                  mobileOpen={mobileDrawerOpen}
                  onClose={() => setMobileDrawerOpen(false)}
                />
              </Box>
            )}
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                overflowY: "hidden",
              }}
            >
              {auth.status === AuthStatus.AUTHORISED ? (
                <AuthRouter />
              ) : (
                <NotAuthRouter />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
};

export default AppLayout;
