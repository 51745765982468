import React from "react";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
  QueryDefinition,
} from "@reduxjs/toolkit/dist/query";
import {
  UseMutation,
  UseQuery,
  UseLazyQuery,
} from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { MutationActionCreatorResult } from "@reduxjs/toolkit/dist/query/core/buildInitiate";
import { useAppSelector } from "../store";

type LazyQuery<T, Q> = UseLazyQuery<
  QueryDefinition<
    Q,
    BaseQueryFn<
      string | FetchArgs,
      any,
      FetchBaseQueryError,
      object,
      FetchBaseQueryMeta
    >,
    string,
    T,
    "api"
  >
>;

type Query<T, Q> = UseQuery<
  QueryDefinition<
    Q,
    BaseQueryFn<
      string | FetchArgs,
      any,
      FetchBaseQueryError,
      object,
      FetchBaseQueryMeta
    >,
    string,
    T,
    "api"
  >
>;

type Mutation<T, Q> = UseMutation<
  MutationDefinition<
    Q,
    BaseQueryFn<
      string | FetchArgs,
      any,
      FetchBaseQueryError,
      object,
      FetchBaseQueryMeta
    >,
    string,
    T,
    "api"
  >
>;

type MutationResult<T, Q> = MutationActionCreatorResult<
  MutationDefinition<
    Q,
    BaseQueryFn<
      string | FetchArgs,
      any,
      FetchBaseQueryError,
      object,
      FetchBaseQueryMeta
    >,
    string,
    T,
    "api"
  >
>;

export function wrapQueryNEW<T, Q = void>(
  useGenericQuery: Query<T, { orgId: string; queryArg: Q }>
) {
  return (arg: Q, options?: { skip: boolean }): T | null => {
    const orgId = useAppSelector((state) => (state as any).auth?.activeOrgId);
    const genericQuery = useGenericQuery({ orgId, queryArg: arg }, options);

    const [result, setResult] = React.useState<T | null>(null);

    React.useEffect(() => {
      if (genericQuery.isSuccess) {
        setResult(genericQuery.data);
      }
    }, [genericQuery]);

    return result;
  };
}

export function wrapLazyQueryNEW<T, Q = void>(
  useGenericQuery: LazyQuery<T, { orgId: string; queryArg: Q }>
) {
  return (): ((arg: Q) => Promise<T | undefined>) => {
    const orgId = useAppSelector((state) => (state as any).auth?.activeOrgId);
    const [genericQueryTrigger] = useGenericQuery();

    return async (arg: Q) => {
      const queryResult = await genericQueryTrigger({ orgId, queryArg: arg });
      return queryResult.data;
    };
  };
}

export function wrapMutationNEW<T, Q = void>(
  useGenericMutation: Mutation<T, { orgId: string; queryArg: Q }>
) {
  return (): ((
    arg: Q
  ) => MutationResult<T, { orgId: string; queryArg: Q }>) => {
    const orgId = useAppSelector((state) => (state as any).auth?.activeOrgId);
    const [genericMutation, result] = useGenericMutation();
    return (arg: Q) => genericMutation({ orgId, queryArg: arg });
  };
}
