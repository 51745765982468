import jwt, { JwtPayload } from "jsonwebtoken";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { filterNotNil } from "@advocatebase/toolbox";
import { API_BASE_URL } from "../services/consts";
import _ from "lodash";
import {
  AuthStatus,
  ViewMode,
  AdvocateBaseRole,
  TokenPayload,
  AuthState,
  State,
} from "./types";

export const authHeader = (headers: Headers, state: State) => {
  if (
    state.auth &&
    state.auth.status === AuthStatus.AUTHORISED &&
    state.auth.token
  ) {
    // return authorization header with jwt token
    headers.set("authorization", `Bearer ${state.auth.token}`);
  }
  return headers;
};

interface CompletionHandlers {
  onSuccess: () => void;
  onFailure: () => void;
}

export const login = createAsyncThunk<
  string | null,
  {
    email: string;
    password: string;
  } & CompletionHandlers,
  {
    state: State;
  }
>(
  "authSlice/login",
  async ({ email, password, onSuccess = () => {}, onFailure = () => {} }) => {
    try {
      const response = await fetch(`${API_BASE_URL}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify({ email, password }),
      });
      if (response.ok) {
        const result = await response.json();
        onSuccess();
        return result.token;
      }
      throw Error();
    } catch (e) {
      onFailure();
      return null;
    }
  }
);

export const setOrganisationType = "authSlice/setOrganisation";
export const setOrganisation = createAsyncThunk<
  string | null,
  {
    orgId: string | null;
  } & CompletionHandlers,
  {
    state: State;
  }
>(
  setOrganisationType,
  (
    { orgId = null, onSuccess = () => {}, onFailure = () => {} },
    { getState }
  ) => {
    const auth = getState().auth;

    if (
      !auth ||
      // User must be authorised to continue
      auth.status !== AuthStatus.AUTHORISED ||
      // User must be part of this organisation to continue
      !auth.tokenPayload?.organisations.some((org) => org.id === orgId)
    ) {
      onFailure();
      return null;
    }

    onSuccess();
    return orgId;
  }
);

export const setAdvocateBaseAdminViewType =
  "authSlice/setAdvocateBaseAdminView";
export const setAdvocateBaseAdminView = createAsyncThunk<
  boolean,
  CompletionHandlers,
  {
    state: State;
  }
>(
  setAdvocateBaseAdminViewType,
  ({ onSuccess = () => {}, onFailure = () => {} }, { getState }) => {
    const auth = getState().auth;

    if (
      !auth ||
      // User must be authorised to continue
      auth.status !== AuthStatus.AUTHORISED ||
      // User must be an AdvocateBase admin to continue
      ![AdvocateBaseRole.ADMIN].includes(auth.tokenPayload.role)
    ) {
      onFailure();
      return false;
    }

    onSuccess();
    return true;
  }
);

// The logic for clearing the viewMode and activeOrgId is done within the builder near the bottom of this file
export const changeOrganisationType = "authSlice/changeOrganisation";
export const changeOrganisation = createAsyncThunk(
  changeOrganisationType,
  () => null
);

// Used for changing the password in the case of a forgotten password. If the user is logged in, a separate method changePassword in usersApi.js is used.
export const setNewPassword = createAsyncThunk<
  string | null,
  {
    oldPassword: string;
    newPassword: string;
    token: string;
  } & CompletionHandlers,
  {
    state: State;
  }
>(
  "authSlice/setNewPassword",
  async ({
    oldPassword,
    newPassword,
    token, // this could be the password reset token or the general authorisation token. Note: the old password must be supplied if this is the general token.
    onSuccess = () => {},
    onFailure = () => {},
  }) => {
    const isPasswordToken =
      !!(jwt.decode(token) as JwtPayload)?.passwordReset ||
      !!(jwt.decode(token) as JwtPayload)?.passwordInitialSet;

    try {
      const response = await fetch(`${API_BASE_URL}/auth/changePassword`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(filterNotNil({ oldPassword, newPassword })),
      });
      if (response.ok) {
        onSuccess();
        if (!isPasswordToken) {
          // This was a logged-in user changing their password, so update the auth token (and stay logged in)
          const result = await response.json();
          return result.token;
        }
      } else {
        throw Error();
      }
    } catch (e) {
      onFailure();
    }
    if (isPasswordToken) {
      // If using a password reset token, always logout (clear the stored JWT) for security reasons: [see https://cheatsheetseries.owasp.org/cheatsheets/Forgot_Password_Cheat_Sheet.html]
      return null;
    } else {
      // If already logged in, even if failure happens, stay logged in.
      return token;
    }
  }
);

const authorisedState = (token: string) => {
  const tokenPayload = jwt.decode(token) as TokenPayload;

  let activeOrgId: string | null = null;
  let viewMode: ViewMode | null = null;
  // If there is no ambiguity as to which viewMode or organisation to login as, do that.
  if (
    tokenPayload.organisations.length === 1 &&
    ![AdvocateBaseRole.ADMIN].includes(tokenPayload.role)
  ) {
    // Only one organisation & not an AdvocateBase admin
    activeOrgId = tokenPayload.organisations[0].id;
    viewMode = ViewMode.ORGANISATION;
  } else if (
    tokenPayload.organisations.length === 0 &&
    [AdvocateBaseRole.ADMIN].includes(tokenPayload.role)
  ) {
    // No organisations & an AdvocateBase admin
    activeOrgId = null;
    viewMode = ViewMode.ADVOCATEBASE;
  }

  // ie: If there is only one organisation associated with this user, set that as the active organisation. Otherwise, set activeOrgId to null

  return {
    status: AuthStatus.AUTHORISED,
    token,
    tokenPayload,
    activeOrgId,
    viewMode,
  } as AuthState;
};

const authSlice = createSlice({
  name: "auth",
  initialState: { status: AuthStatus.PENDING } as AuthState,
  reducers: {
    logout() {
      return { status: AuthStatus.UNAUTHORISED };
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(login.fulfilled, (state, { payload: token }) =>
      token ? authorisedState(token) : { status: AuthStatus.UNAUTHORISED }
    );
    builder.addCase(setOrganisation.fulfilled, (state, { payload: orgId }) => ({
      ...state,
      activeOrgId: orgId,
      viewMode: !_.isNil(orgId) ? ViewMode.ORGANISATION : null,
    }));
    builder.addCase(
      setAdvocateBaseAdminView.fulfilled,
      (state, { payload: isSuccess }) => ({
        ...state,
        activeOrgId: null,
        viewMode: isSuccess ? ViewMode.ADVOCATEBASE : null,
      })
    );
    builder.addCase(changeOrganisation.fulfilled, (state) => ({
      ...state,
      activeOrgId: null,
      viewMode: null,
    }));
    builder.addCase(setNewPassword.fulfilled, (state, action) =>
      action.payload
        ? authorisedState(action.payload)
        : { status: AuthStatus.UNAUTHORISED }
    );
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = authSlice;

// Extract and export each action creator by name
export const { logout } = actions;

// Export the reducer, either as a default or named export
export default reducer;
